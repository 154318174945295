<template>
    <PageBlock 
        title="Risk-Free Rate"
        :can-fullscreen="false"
        is-table
        :list="list">

        <vxe-table-column 
            field="ind_id" 
            title="Index" 
            min-width="120">
        </vxe-table-column>

        <vxe-table-column 
            title="Piece/Value" 
            width="90"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.ind_percent"
                    format="0.00%"/>
            </template>
        </vxe-table-column>
            
    </PageBlock>
</template>

<script>
import PageBlock from '@/components/PageBlock'
import FormattedNumber from '@/components/Formats/FormattedNumber'
export default {
    name: 'portfolio_benchmarks',
    components: { PageBlock, FormattedNumber },

    props: {
        portfolio: {
            required: true,
        },
    },

    computed:{
        list(){
            if (this.portfolio.risk_free_rate_set) {
                return [{ ind_id: "Fixed Rate",  ind_percent: this.portfolio.risk_free_rate_set / 100}]
            }
            else {
                return this.portfolio.risk_free_rate || []
            }
        }  
    },
}
</script>
